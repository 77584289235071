import React from "react"

import styles from "../../styles/main.module.css"

export default function BooksSept2020() {
  const text =
    "When the pandemic started I began to read more than I'd been doing in the recent past. I thought it would be nice to sporadically share what I've been reading and what I think some of the best books were. Over the course of the past few months I've mostly read approachable, almost, 'pop' books, though in the future I want to read more literature and history. I also intend to run through some math books this semester/year that I am gapping. Sometime I might share a top ten or twenty books list."

  // format is (Recommend/Don't Recommend/Might Recommend -> 1, -1, 0), title, author, description/comments
  const books = [
    [1, "Steve Jobs", "Walter Isaacson", "Long, but enjoyable read. Goes into a lot of detail. Big duality in Jobs' nature that plays throughout his life-story. Feels like big-man history, but I can't lie that I have a little bit of a soft spot for that sort of writing."],
    [1, "Elon Musk: Tesla, SpaceX, and the Quest for a Fantastic Future", "Ashlee Vance", "I thought this was a fun read. Somewhat inspirational, but filled with ideas that maybe even you can copy. Similarly to Jobs' biography, reads a little bit like big-man history, but I had a blast with this one.",],
    [1, "The Three Body Problem Trilogy", "Cixin Liu", "One of my favorite Sci-Fi series of all time. Seems a bit too Chinese-centric, but I presume someone reading American Sci-Fi would feel the same way regarding the United States. First and last books are the best; second one is solid too though."],
    [0, "The Way of Zen", "Alan Watts", "Written in Alan Watts' own words, a very approachable explanation of Zen. Very helpful to guide practice, though Alan Watts is known to not be an excellent source in many regards (so probably get some other sources too). Do not read this book for theory or Buddhist approaches to Buddhism."],
    [0, "Civilization and Its Discontents", "Sigmund Freud", "Interesting exploration of Civilization. Covers themes regarding aggressiveness, libidinous instincs, the eternal struggle between Eros and Thanatos, and Civilizational guilt. Better to read in conjunction with other work by Freud for context. This being my first reading of Freud, many ideas appeared imprecise, and thus not concretely meaningful. There are also some funny tidbits, sometimes more poetic than scientific, regarding theories that are a bit 'out there.'"],
    [1, "Waking Up", "Sam Harris", "Enjoyable and fascinating read on the self. Not particularly helpful for practice and understanding the idea fo No Self, but a great exploration from a more scientific/Western perspective. Every chapter had interesting information that I didn't know. Good as a book to start looking into spirituality."],
    [0, "Foundations of Buddhism", "Rupert Gethin", "Concise and useful introduction to Buddhist history and theory. Good starting point for Buddhism (i.e. buy this and keep it as a reference for a broad view from which you can choose different options to dive deep into). Had a couple semi-useful chapters for practice."],
    [1, "Permanent Record", "Edward Snowden", "Good autobiography from Snowden. Interesting, detailed book on the whole situation regarding government surveillance throughout 2000's. Has a bent towards righteousness, and it is hard to not feel pessimistic. Descriptions of the powerful are disheartening."],
    [-1, "The Subterraneans", "Jack Kerouac", "Jazz/Bop writing pushed to the limit. Hard to follow, but not a terrible idea in theory. Reads more like someone going mad than Zen (though you could argue...) or holiness. Given I don't listen to bop it's also hard to appreciate the rythm."],
    [0, "Visions of Gerard", "Jack Kerouac", "Tales of Kerouac's brother Gerard. Well written and evince Gerard as holy. Makes you think a little."],
    [1, "Digital Minimalism", "Cal Newport", "Very useful guide to becoming a digital minimalist in a cluttered world. It's hard not to feel information overload in our society, and Cal's treatise provides good techniques and ideas to escape that while still functioning well."],
    [0, "The Universe in a Nutshell", "Stephen Hawking", "A slightly worse version of A Brief History of Time, though it has a lot of pictures."],
    [0, "The Art of Invisibility", "Kevin Mitnick", "Useful fact-dump with good short stories on security and privacy. Don't read this unless you actually plan on doing anything."],
    [0, "The Vanishing American Adult", "Ben Sasse", "Reads somewhere between 'get off my lawn' and a useful treatise on Education in our society. Brings up some good points, and may be, even, useful to decide on ways to become more of an 'adult.' Not sure you'll need this to do any of that, however."],
    [-1,"Soonish", "Kelly Weinersmith and Zach Weinersmith", "Semi-interesting. Good ideas explored in certain areas, but treated with few details. Opposite of technical. Tries too hard to be funny alá xkcd."]
  ]

  // ternary confuses it not sure why
  const items_switch = (num, items) => {
    if (num > 0) {
      return items[0] // "8fc93a" // Recommend
    }
    else if (num < 0) {
      return items[1] // "dc493a" // Don't Recommend
    }
    else {
      return items[2] // "4392f1" // Might Recomend
    }
  }

  const recommend = (num) => {
    const color_items = [styles.green_text, styles.red_text, styles.blue_text]
    const recommend_items = ["Recommend", "Don't Recommend", "Might Recommend"]
    return (
        <span
          className={items_switch(num, color_items)}
        >
          {items_switch(num, recommend_items)}
        </span>
    )
  }

  return (
    <React.Fragment>
      <h2>Book Recommendations</h2>
      <hr className={styles.top_seperator} />
      <p>{text}</p>
      <ul>
        {books.map(bookParams => (
          <li>
            <h3>
              {bookParams[1]} by {bookParams[2]}
            </h3>
            {recommend(bookParams[0])}
            <p>{bookParams[3]}</p>
          </li>
        ))}
      </ul>
      <div className={styles.phone_home}>
        <a href="/">Click me to return home.</a>
      </div>
    </React.Fragment>
  )
}
